module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"root":"C:\\Users\\georg\\Documents\\Developer\\MellowArpeggiation.github.io","gatsbyRemarkPlugins":[{"resolve":"C:/Users/georg/Documents/Developer/MellowArpeggiation.github.io/node_modules/gatsby-remark-images","id":"e2f7e351-0cab-591c-aec7-e992cdbbc8d6","name":"gatsby-remark-images","version":"6.25.0","modulePath":"C:\\Users\\georg\\Documents\\Developer\\MellowArpeggiation.github.io\\node_modules\\gatsby-remark-images\\index.js","pluginOptions":{"plugins":[],"maxWidth":500,"linkImagesToOriginal":false,"disableBgImageOnAlpha":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"C:/Users/georg/Documents/Developer/MellowArpeggiation.github.io/node_modules/gatsby-remark-prismjs","id":"96900835-4b61-53a8-8a41-f46b0b717fb0","name":"gatsby-remark-prismjs","version":"6.25.0","modulePath":"C:\\Users\\georg\\Documents\\Developer\\MellowArpeggiation.github.io\\node_modules\\gatsby-remark-prismjs\\index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":500,"linkImagesToOriginal":false,"disableBgImageOnAlpha":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2c6fd6d7fd2739211afefd7eea0a8384"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
